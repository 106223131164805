import { css } from '@emotion/core';
import React from 'react';
import Fade from './Fade';

const styles = css`
	max-width: 700px;
	margin-bottom: 100px;
	ul {
		list-style: none;
		padding: 0;
	}
`;

const LinksList = ({ children }) => (
	<div css={styles}>
		<ul>
			{React.Children.map(children, child => (
				<li>
					<Fade bottom>
						{child}
					</Fade>
				</li>
			))}
		</ul>
	</div>
);

export default LinksList;
