import { css } from '@emotion/core';
import React from 'react';
import Collapsible from 'react-collapsible';
import COLORS from '../styles/colors';

const styles = css`
	.Collapsible {
		border-bottom: 1px solid ${COLORS.grayLight};
		transition: border-bottom-color .2s ease-out;
		&:hover {
			border-bottom-color: ${COLORS.primary};
		}
	}
	.Collapsible__trigger {
		display: block;
		color: ${COLORS.primary};
		text-decoration: none;
		font-size: 18px;
		line-height: ${30 / 18};
		padding: 10px 0;
		cursor: pointer;
	}
	.Collapsible__contentInner {
		padding-top: 10px;
	}
`;

const LinkItem = ({ title, children }) => (
	<div css={styles}>
		<Collapsible trigger={title}>
			{children}
		</Collapsible>
	</div>
);

export default LinkItem;
