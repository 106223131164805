import React from 'react';
import AccordeonItem from '../components/AccordeonItem';
import ContactBox from '../components/ContactBox';
import Fade from '../components/Fade';
import IntroTitleBox from '../components/IntroTitleBox';
import Container from '../components/layout/Container';
import LinksList from '../components/LinksList';
import MainTitle from '../components/MainTitle';
import Meta from '../components/seo/Meta';
import SrOnlyTitle from '../components/seo/SrOnlyTitle';
import MainLayout from '../layouts/MainLayout';


const Index = () => (
	<MainLayout>
		<Meta
			title="Kontakty"
			description="Naše spolupráce může začít krátkým e-mailem, telefonátem nebo potřesením ruky."
		/>

		<SrOnlyTitle>Kontakty</SrOnlyTitle>

		<IntroTitleBox>
			<Container>
				<MainTitle>
					Naše spolupráce může začít krátkým e-mailem, telefonátem nebo potřesením ruky.
				</MainTitle>
			</Container>
		</IntroTitleBox>

		<Container>
			<ContactBox imageSrc="kontakty-01.jpg">
				<p>
					<strong>Volejte</strong><br />
					<a href="tel:+420602742447">+420 608 94 17 17</a>
				</p>

				<p>
					<strong>Pište</strong><br />
					<a href="mailto:ondrej@palatlegal.cz">office@palatlegal.cz</a>
				</p>
			</ContactBox>

			<ContactBox imageSrc="kontakty-02.jpg">
				<p>
					<strong>Kancelář</strong><br />
					Konviktská 291/24<br />
					Praha – Staré město
				</p>
			</ContactBox>

			<Fade bottom>
				<h2 className="h3">Mohlo by vás zajímat, než se ozvete</h2>
			</Fade>

			<LinksList>
				<AccordeonItem title="Co od vás potřebuji vědět při prvním kontaktu?">
					<p>
						Při prvním kontaktu potřebuji zejména obšírné informace o vašich právních potřebách, včetně všech dokumentů vztahujících se k&nbsp;danému případu.
					</p>
				</AccordeonItem>
				<AccordeonItem title="S čím vším vám rád pomohu.">
					<p>
						Rád vám pomohu s&nbsp;obchodním, korporátním, insolvenčním, pracovním či nemovitostním právem, obecně Vám rád pomohu se záležitostmi souvisejícími s&nbsp;podnikáním či majetkem.
					</p>
				</AccordeonItem>
				<AccordeonItem title="Služby, se kterými vám nemohu pomoci.">
					<p>
						Neposkytuji právní poradenství v nahodilých&nbsp;trestněprávních a&nbsp;rodinně právních záležitostech. Tyto poskytuji pouze dlouhodobým klientům v&nbsp;rámci komplexního poradenství.
					</p>
				</AccordeonItem>
				<AccordeonItem title="Jak bude probíhat náš první kontakt.">
					<p>
						První kontakt, tedy obecné posouzení právního problému a&nbsp;nastínění řešení může proběhnout online, prostřednictvím e-mailu nebo telefonu. Tato konzultace však nepovede k&nbsp;poskytnutí právní služby, ale k&nbsp;nastínění možných řešení a&nbsp;variant.
					</p>
					<p>
						Právní služby totiž nemohou být poskytovány anonymně, již jen s&nbsp;ohledem na ochranu zájmů klientů a&nbsp;dodržení všech povinností advokáta. Mezi takovými je zejména povinnost mlčenlivosti advokáta o&nbsp;službách, které poskytl klientům, a&nbsp;povinnost vyvarovat se střetu zájmů&nbsp;–&nbsp;který by mohl nastat, kdyby advokát poskytoval služby protistranám.
					</p>
					<p>
						Až osobní setkání, popřípadě jiné utvrzení identity klienta pak může vést k&nbsp;uzavření smlouvy o&nbsp;poskytování právních služeb a&nbsp;zahájení spolupráce. Prostředkem k&nbsp;identifikaci klienta je například použití jeho kvalifikovaného elektronického podpisu, nebo datové schránky ke&nbsp;komunikaci.
					</p>
					<p>
						Další služby po uzavření smlouvy o&nbsp;poskytování právních služeb již mohou být poskytovány online.
					</p>
				</AccordeonItem>
				<AccordeonItem title="Kolik stojí moje služby.">
					<p>
						Standardní hodinová sazba poskytování právních služeb nebo konzultací je 2.500&nbsp;Kč&nbsp;bez&nbsp;DPH.
					</p>
					<p>
						U&nbsp;dlouhodobější spolupráce většího rozsahu, nebo ve&nbsp;specifických případech lze dohodnout i&nbsp;alternativní způsoby odměny, které vycházejí z&nbsp;vyhlášky č. 177/1996 Sb., advokátní tarif.
					</p>
					<p>
						U&nbsp;nových klientů bez doporučení je vyžadována záloha na provedené služby.
					</p>
				</AccordeonItem>
				<AccordeonItem title="Moje specializace jsou rodinné firmy. Co to znamená?">
					<p>
						Má specializace vychází z&nbsp;mé osobní zkušenosti s&nbsp;budováním a&nbsp;řízením rodinných firem, kdy právní služby poskytované přímo společnosti&nbsp;-&nbsp;příprava smluv, interní dokumentace, vyjednávání s&nbsp;obchodními partnery, a&nbsp;podobně, mají přesah v&nbsp;poskytování právního poradenství a&nbsp;konzultací pro osoby činné ve společnosti. Kromě běžné agendy se dané konzultace týkají zejména nastavení rodinných vazeb vůči společnosti, předávání společnosti další generaci, ochrana majetku uvnitř rodiny a&nbsp;další.
					</p>
				</AccordeonItem>
				<AccordeonItem title="Přijedete osobně? Jak a kde zaparkovat.">
					<p>
						V&nbsp;místě mé kanceláře je možné zaparkovat na&nbsp;modré návštěvnické zóně (parkujvklidu.cz), popřípadě je možné využít zastávku metra či tramvají&nbsp;–&nbsp;Národní třída. Zaparkovat se také dá v&nbsp;Palladiu či OC Kotva, popřípadě v&nbsp;Národním divadle.
					</p>
				</AccordeonItem>
				<AccordeonItem title="Nejste z Prahy? Jak pracuji s klienty po celé ČR.">
					<p>
						Vzdálená spolupráce není nemožná, spolupracuji s&nbsp;českými klienty od&nbsp;Prahy přes Brno a&nbsp;Zlín až&nbsp;Ostravu, které více méně pravidelně navštěvuji. Běžná spolupráce však probíhá za&nbsp;pomoci elektronické komunikace.
					</p>
					<p>
						Zahraniční klienti se mnou spolupracují obdobně, elektronicky po&nbsp;emailu, případně si dohodneme občasná setkání v&nbsp;České republice či v&nbsp;zahraničí.
					</p>
				</AccordeonItem>
				<AccordeonItem title="Informace pro spotřebitele">
					<p>
						Česká advokátní komora byla dne 5.&nbsp;února 2016 pověřena Ministerstvem průmyslu a&nbsp;obchodu České republiky mimosoudním řešením spotřebitelských sporů pro oblast sporů mezi advokátem a&nbsp;spotřebitelem ze&nbsp;Smluv o&nbsp;poskytování právních služeb (na&nbsp;základě zákona č.&nbsp;634/1992&nbsp;Sb., o&nbsp;ochraně spotřebitele). Internetová stránka této pověřené osoby je www.cak.cz.
					</p>
				</AccordeonItem>
			</LinksList>
		</Container>
	</MainLayout>
);

Index.propTypes = {};

export default Index;
