import { css } from '@emotion/core';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Fade from './Fade';
import COLORS from '../styles/colors';
import { smUp } from '../styles/breakpoints';

const styles = css`
	font-size: 24px;
	line-height: ${44 / 30};
	color: ${COLORS.black};
	margin-bottom: 50px;
	.content {
		margin-bottom: 25px;
		> :last-child {
			margin-bottom: 0;
		}
	}
	@media ${smUp} {
		font-size: 30px;
		display: flex;
		align-items: center;
		.image {
			flex: 1 1 540px;
			margin-right: 100px;
		}
		.content {
			order: 1;
			flex: 1 0 auto;
			margin-bottom: 0;
		}
	}
`;

const ContactBox = ({ children, imageSrc, data }) => (
	<div css={styles}>
		<div className="content">
			<Fade right>
				{children}
			</Fade>
		</div>

		<div className="image">
			<Fade left>
				<StaticQuery
					query={graphql`
						query {
							allImageSharp {
								edges {
									node {
										fluid(maxWidth: 1920, quality: 100) {
											...GatsbyImageSharpFluid_withWebp
										}
									}
								}
							}
						}
					`}
					render={data => (
						<Img fluid={data.allImageSharp.edges.find(
							image => image.node.fluid.src.split('/').pop() === imageSrc,
						).node.fluid}
						/>
					)}
				/>
			</Fade>
		</div>
	</div>
);

export default ContactBox;
